/**
 * Socrative Input
 *
 *    accept          {string}   Comma-separated string of allowed file extensions (e.g. '.xls, .xlsx'). Used when the type is 'file'.
 *    autoCapitalize  {string}   The value of the HTML autocapitalize attribute (e.g. 'off', 'characters', 'sentences', 'words')
 *    autoComplete    {string}   The value of the HTML autocomplete attribute (e.g. 'off' or 'on')
 *    autoCorrect     {string}   The value of the HTML autocorrect attribute (e.g. 'off' or 'on')
 *    autoFocus       {boolean}  Whether the input should be auto focused
 *    className       {string}   CSS class to apply to the input
 *    containerClass  {string}   CSS class to apply to the entire container
 *    disabled        {boolean}  Whether the input is disabled
 *    error           {string}   Error text for the tooltip
 *    hideTooltip     {boolean}  Whether the tooltip is hidden
 *    id              {string}   HTML id attribute for the input
 *    keyValue        {string}   Value to use as the special React 'key' property (for uniquely identifying this component)
 *    label           {string}   Text of the label above the input. If you pass a label, you mast pass a unique id for the input.
 *    maxLength       {number}   Maximum number of characters allowed in the input
 *    onBlur          {Function} Callback to execute on blur
 *    onChange        {Function} Callback to execute on change
 *    onKeyDown       {Function} Callback to execute on key down
 *    onKeyPress      {Function} Callback to execute on key press
 *    name            {string}   html name attribute
 *    pattern         {string}   the html pattern
 *    placeholder     {string}   HTML placeholder attribute for the input
 *    readOnly        {boolean}  Whether the input is read only
 *    status          {object}   A less cumbersome way to display errors and tooltips. See the multiline comment below.
 *    tooltipClass    {string}   CSS class to apply to the tooltip
 *    tooltipLocation {string}   Location of the tooltip (either 'above' or 'below')
 *    type            {string}   Type of the input (defaults to 'text')
 *    value           {string}   Input value
 *    refValue        {string}   Value to use as the special React 'ref' property (for referencing this component)
 */

import * as React from 'react';

import AlertIcon from '../../icons/AlertIcon';
import Tooltip from '../tooltips/Tooltip';

export default class Input extends React.Component {
  render() {
    let label = null;

    if (this.props.label) {
      label = <label htmlFor={this.props.id}>{this.props.label}</label>;
    }

    let error = null;
    let errorChildren = [];

    if (this.props.error) {
      errorChildren = [
        <AlertIcon
          key="errorChildren-alert-icon"
          className="input-error-icon"
        />,
      ];

      if (!this.props.hideTooltip) {
        errorChildren.push(
          <Tooltip
            key="errorChildren-Tooltip"
            location={this.props.tooltipLocation}
            className={this.props.tooltipClass}
            text={this.props.error}
          />
        );
      }

      error = (
        <div className="input-error" data-testid="input-error">
          {errorChildren}
        </div>
      );
    }

    /*
            The following may seem like duplicate code, but it's a less cumbersome way for
            users of this component to trigger its error state and optional tooltip. Just
            pass in an object named "status". If the status object has a boolean property
            named "error" set to true, the red border and alert icon will be shown. If the
            status object also has a "message" property, the tooltip will be shown too.
         */
    if (this.props.status && this.props.status.error) {
      errorChildren = [
        <AlertIcon
          key="errorChildren-AlertIcon"
          className="input-error-icon"
        />,
      ];

      if (this.props.status.message) {
        errorChildren.push(
          <Tooltip
            key="errorChildren-Tooltip"
            location={this.props.tooltipLocation}
            className={this.props.tooltipClass}
            text={this.props.status.message}
          />
        );
      }

      error = (
        <div className="input-error" data-testid="input-error">
          {errorChildren}
        </div>
      );
    }

    let containerClass = '';

    if (this.props.containerClass) {
      containerClass += ' ' + this.props.containerClass;
    }

    let inputOptions = {
      accept: this.props.accept,
      autoFocus: this.props.autoFocus || false,
      className: this.props.className,
      disabled: this.props.disabled || false,
      id: this.props.id,
      maxLength: this.props.maxLength,
      onBlur: (event) => {
        if (this.props.onBlur) {
          this.props.onBlur(event);
        }
      },
      onChange: (event) => {
        if (this.props.onChange) {
          this.props.onChange(event);
        }
      },
      onFocus: (event) => {
        if (this.props.onFocus) {
          this.props.onFocus(event);
        }
      },
      onKeyDown: (event) => {
        if (this.props.onKeyDown) {
          this.props.onKeyDown(event);
        }
      },
      onKeyPress: (event) => {
        if (this.props.onKeyPress) {
          this.props.onKeyPress(event);
        }
      },
      onKeyUp: (event) => {
        if (this.props.onKeyUp) {
          this.props.onKeyUp(event);
        }
      },
      pattern: this.props.pattern,
      placeholder: this.props.placeholder,
      readOnly: this.props.readOnly || false,
      title: this.props.title || undefined,
      type: this.props.type || 'text',
      value: this.props.value,
    };

    if (this.props.autoCapitalize) {
      inputOptions.autoCapitalize = this.props.autoCapitalize;
    }

    if (this.props.autoComplete) {
      inputOptions.autoComplete = this.props.autoComplete;
    }

    if (this.props.autoCorrect) {
      inputOptions.autoCorrect = this.props.autoCorrect;
    }

    if (this.props.keyValue) {
      inputOptions.key = this.props.keyValue;
    }

    if (this.props.refValue) {
      inputOptions.ref = this.props.refValue;
    }

    if (this.props.type === 'number' && this.props.min) {
      inputOptions.min = this.props.min;
    }
    if (this.props.name) {
      inputOptions.name = this.props.name;
    }

    return (
      <span className={'input-container' + containerClass}>
        {label}
        <div
          className={
            'input-block' +
            (this.props.error || (this.props.status && this.props.status.error)
              ? ' input-block-error'
              : '')
          }
        >
          <input {...inputOptions} />
          {error}
        </div>
      </span>
    );
  }
}
