/* eslint-disable no-useless-escape */

'use strict';

const Constants = require('../shared/Constants');

class Validations {
  isValidDatabaseId(id) {
    return /^\d+$/.test(id) && id > 0;
  }

  /**
   * Check whether an uploaded file matches any of the known Excel mime types.
   * @param {object} file The uploaded Excel file.
   * @returns {boolean}
   */
  isValidExcelFile(file) {
    return (
      [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
        'application/octet-stream',
      ].indexOf(file.mimetype) !== -1
    );
  }

  /**
   * Check whether an email passes the regex validation defined by the HTML5 spec:
   *
   * https://www.w3.org/TR/html5/sec-forms.html#email-state-typeemail
   *
   * Note that our regex deviates from the HTML5 spec by requiring at least one
   * period in the domain. This was achieved by changing the final * character
   * to a + character.
   *
   * @param email {string} The email to validate.
   * @returns {boolean}
   */
  isValidEmail(email) {
    return (
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/.test(
        email
      ) && email.length <= Constants.MAX_STRING_LENGTH_255
    );
  }

  isValidParentId(parentId) {
    return parentId === null || (/^\d+$/.test(parentId) && parentId > 0);
  }

  /**
   * Check whether a new password is valid. This cannot be used for login validation because early
   * versions of Socrative did not enforce password length. This can, however, be used for account
   * creation and password reset.
   * @param password The password to validate.
   * @returns {boolean}
   */
  isValidPassword(password) {
    return (
      password &&
      password.length >= Constants.MIN_PASSWORD_LENGTH &&
      password.length <= Constants.MAX_PASSWORD_LENGTH
    );
  }

  /**
   * Check whether a license key is in valid format
   * @param key
   * @returns {boolean}
   */
  isValidLicenseKey(key) {
    return (
      /^[0-9A-F]{25}$/i.test(key) || /^([0-9A-F]{5}-){4}[0-9A-F]{5}$/i.test(key)
    );
  }
}

module.exports = new Validations();
