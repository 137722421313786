'use strict';

import { LANGUAGES } from '@showbie-socrative/socrative-utils';
import { translate } from '@showbie-socrative/socrative-utils/lib/translator/client';
import { addDays, endOfDay, isFuture, isPast, parseISO } from 'date-fns';

import { isValidName } from './isValidName';

const validations = require('../Validations');
const Constants = require('../Constants');
const request = require('../Request');
const LicenseModel = require('./LicenseModel');
const BaseModel = require('./BaseModel');
const _ = require('underscore');

const PRO_GRACE_PERIOD_DAYS = 1;

class AccountModel extends BaseModel {
  constructor() {
    super();
    /* --------- */
    /*   Client  */
    /* --------- */
    this.banner = {
      message: '', // The banner message displayed at the top of the profile.
      type: Constants.BANNER_TYPE_ERROR, // The type of the banner. One of the BANNER_TYPE_<TYPE> constants.
    };
    this.buyingLicense = false; // Whether the teacher has clicked "Renew License" or "Upgrade".
    this.deletingAccount = false; // Whether the teacher has clicked on "Delete Account".
    this.currentPassword = ''; // The value entered by the teacher in the "Current Password" field.
    this.demoVisible = {}; // An object representing the elements that are visible on the account demographics view.
    this.email2 = ''; // The email entered by the teacher in the "Confirm Email" field.
    this.emailVerified = false; // Whether the email has been verified by the /v3/accounts/check/:email API.
    this.errors = {
      // Collection of account input errors. License input errors are handled in LicenseModel.
      firstName: { error: false },
      lastName: { error: false },
      email: { error: false, message: '' },
      email2: { error: false, message: '' },
      password: { error: false, message: '' },
      password2: { error: false, message: '' },
      country: { error: false },
      language: { error: false },
      orgType: { error: false },
      schoolId: { error: false },
      schoolName: { error: false },
      zipCode: { error: false, message: '' },
      state: { error: false },
      role: { error: false },
      orgName: { error: false },
      terms: { error: false },
      currentPassword: { error: false, message: '' },
      city: { error: false, message: '' },
    };
    this.googleUser = false; // Whether the teacher is a Google user.
    this.headerText = ''; // Header text displayed at the top of the last step during account creation.
    this.hasRecentlyExpired = false;

    // The initial properties reflect the values that were fetched when the teacher first navigated to the profile.
    // If the teacher makes a profile change, does not save, then navigates away and comes back, the corresponding
    // properties are restored to their initial values.
    this.initialCountry = '';
    this.initialDistrictName = '';
    this.initialEmail = '';
    this.initialFirstName = '';
    this.initialLanguage = '';
    this.initialLastName = '';
    this.initialOrgName = '';
    this.initialOrgType = '';
    this.initialRole = [''];
    this.initialSchoolId = null;
    this.initialSchoolName = '';
    this.initialState = '';
    this.initialUniversity = '';
    this.initialZipCode = '';
    this.initialCity = '';
    this.initialDescription = '';
    this.initialPhoneNumber = null;
    this.initialBlingId = null;

    this.licenseModel = new LicenseModel(); // License model used during the last step of account creation.
    this.loadingByCountry = false; // Whether the view is loading international schools by country name from MC.
    this.loadingByZip = false; // Whether the view is loading domestic schools by zip code from MC.
    this.password2 = ''; // The password entered by the teacher in the "Confirm Password" field.
    this.renewalWindow = null; // Date object indicating the teacher's renewal window.
    this.selectedAccountType = null; // The type of account selected on the fourth step of account creation. One of the <TYPE>_USER constants.
    this.showChangePassword = false; // Whether the "Change Password" link is visible on the profile view.
    this.showLanguageTooltip = false; // Whether the language tooltip is visible (the teacher clicked the language select while an actvity is running).
    this.showPasswords = false; // Whether the "Show Passwords" box is checked on the profile view.
    this.step1Status = Constants.STATUS_SELECTED; // The status of the first step (and its little numbered circle) in the create account flow.
    this.step2Status = Constants.STATUS_IDLE; // The status of the second step (and its little numbered circle) in the create account flow.
    this.step3Status = Constants.STATUS_IDLE; // The status of the third step (and its little numbered circle) in the create account flow.
    this.step4Status = Constants.STATUS_IDLE; // The status of the fourth step (and its little numbered circle) in the create account flow.
    this.stripeError = ''; // Error message returned by Stripe during a failed purchase attempt.
    this.subHeaderText = ''; //  Sub header text displayed at the top of the last step during account creation.
    this.termsChecked = false; // Whether the "I agree to the terms" box is checked.
    this.schoolSearchValue = { text: '' }; // Store the full value of school search between steps

    /* --------- */
    /*   Shared  */
    /* --------- */
    this.authToken = ''; // The auth token on the account.
    this.hmacToken = ''; // The HMAC used for identity verification in Intercom
    this.autoRenew = false; // The value of the auto renew setting.
    this.canAutoRenew = false; // Whether auto renew can be toggled (only true if the license has a Stripe customer id).
    this.country = ''; // The country on the account.
    this.description = ''; // The description of the account. For Corp/Other, the organization name is used.
    this.districtName = ''; // The district name on the account.
    this.email = ''; // The email on the account.
    this.expiration = null; // Date object indicating when the teacher's license expires.
    this.customerType = null; // The type of customer if they are a legacy License. One of the LicenseTransactionTypes constants.
    this.firstName = ''; // The first name on the account.
    this.isBuyer = false; // Whether the teacher bought the license to become PRO.
    this.id = 0; // The id of the account in the database.
    this.publicId = null; // The public id of the account in the database
    this.language = 'en'; // The language on the account.
    this.lastName = ''; // The last name on the account.
    this.licenseKey = ''; // The license key that makes the teacher PRO.
    this.mcUser = false; // Whether the teacher is an MC user.
    this.orgName = ''; // The organization name on the account.
    this.orgType = ''; // The organization type on the account. One of the ORGANIZATION_<TYPE> constants.
    this.password = ''; // The password for the account. This will be hashed before database insertion.
    this.role = ['']; // The role(s) on the account. One or more of the ROLE_<TYPE> constants.
    this.schoolId = null; // The school id on the account.
    this.schoolName = ''; // The school name on the account.
    this.state = ''; // The two-letter state on the account.
    this.city = '';
    this.university = ''; // The university name on the account.
    this.zipCode = ''; // The zip code on the account.
    this.betaFlags = {}; // Beta flags on the account.
    this.amplitudeData = {};
    this.phoneNumber = null; // Phone number stored for teacher
    this.blingId = null;
    this.version = null; // The version number of the account (legacy or new or consolidated)
    this.isPro = false;
    this.freeQuizLimit = null;
  }

  /* ------------------------ */
  /*   Client Helper Methods  */
  /* ------------------------ */

  validateProfile(callback) {
    // Make sure the inputs are not empty:
    this.errors.firstName.error = !this.isValidFirstName();
    this.errors.lastName.error = !this.isValidLastName();

    if (
      this.currentPassword.length > 0 ||
      this.password.length > 0 ||
      this.password2.length > 0
    ) {
      if (!this.isValidCurrentPassword()) {
        this.errors.currentPassword.error = true;
      }

      // Make sure the password is long enough:
      if (!this.isValidPassword()) {
        this.errors.password.error = true;
        this.errors.password.message = translate(
          'Please use 8 or more characters.'
        );
      }

      // Make sure the passwords match:
      if (!this.errors.password.error && this.password !== this.password2) {
        this.errors.password2.error = true;
        this.errors.password2.message = translate('The passwords must match.');
      }
    }

    if (!this.isValidEmail()) {
      this.errors.email.error = true;
      this.errors.email.message = translate(
        'This email is not formatted properly.'
      );
      return callback();
    }

    // Make sure the email is available. We do this last since it requires a network request:
    if (!this.errors.email.error && !this.emailVerified) {
      this.verifyEmail(callback);
    } else {
      callback();
    }
  }

  validateCreateProfile(callback) {
    // Make sure the inputs are not empty:
    this.errors.firstName.error = !this.isValidFirstName();
    this.errors.lastName.error = !this.isValidLastName();
    this.errors.email.error = !this.isValidEmail();
    this.errors.email2.error = !this.isValidEmail2();
    this.errors.password.error = !this.isValidPassword();
    this.errors.password2.error = !this.isValidPassword2();

    // Make sure the emails passed our validation regex:
    if (this.errors.email.error) {
      this.errors.email.message = translate('Please enter a valid email.');
    }

    if (this.errors.email2.error) {
      this.errors.email2.message = translate('Please enter a valid email.');
    }

    // Make sure the emails match:
    if (
      !this.errors.email.error &&
      !this.errors.email2.error &&
      this.email !== this.email2
    ) {
      this.errors.email2.error = true;
      this.errors.email2.message = translate('The emails don‘t match.');
    }

    // Make sure the password is long enough:
    if (!this.isValidPassword()) {
      this.errors.password.error = true;
      this.errors.password.message = translate(
        'Please use 8 or more characters.'
      );
    }

    // Make sure the passwords match:
    if (!this.errors.password.error && this.password !== this.password2) {
      this.errors.password2.error = true;
      this.errors.password2.message = translate('The passwords must match.');
    }

    // Make sure the email is available. We do this last since it requires a network request:
    if (
      !this.errors.email.error &&
      !this.errors.email2.error &&
      !this.emailVerified
    ) {
      this.verifyEmail(callback);
    } else {
      callback();
    }
  }

  isCountryUS() {
    return this.country === 'US' || this.country === 'USA';
  }

  isHigherEd() {
    return this.orgType === Constants.ORGANIZATION_HIGHER_ED;
  }

  isK12() {
    return this.orgType === Constants.ORGANIZATION_K12;
  }

  resetPasswordFields() {
    this.showChangePassword = !(this.mcUser || this.googleUser);
    this.showPasswords = false;
    this.currentPassword = '';
    this.password = '';
    this.password2 = '';
  }

  restoreInitialValues() {
    this.firstName = this.initialFirstName;
    this.lastName = this.initialLastName;
    this.email = this.initialEmail;
    this.emailVerified = true;
    this.country = this.initialCountry;
    this.language = this.initialLanguage;
    this.orgType = this.initialOrgType;
    this.schoolId = this.initialSchoolId;
    this.state = this.initialState;
    this.zipCode = this.initialZipCode;
    this.orgName = this.initialOrgName;
    this.schoolName = this.initialSchoolName;
    this.role = this.initialRole;
    this.districtName = this.initialDistrictName;
    this.phoneNumber = this.initialPhoneNumber;
    this.blingId = this.initialBlingId;
    this.city = this.initialCity;
    this.description = this.initialDescription;

    this.errors = {
      firstName: { error: false },
      lastName: { error: false },
      email: { error: false, message: '' },
      email2: { error: false, message: '' },
      password: { error: false, message: '' },
      password2: { error: false, message: '' },
      country: { error: false },
      language: { error: false },
      orgType: { error: false },
      schoolId: { error: false },
      schoolName: { error: false },
      zipCode: { error: false, message: '' },
      state: { error: false },
      role: { error: false },
      orgName: { error: false },
      terms: { error: false },
      currentPassword: { error: false, message: '' },
      city: { error: false, message: '' },
    };
  }

  setExpiration(expiration) {
    const parsedExpiration = parseISO(expiration);
    this.expiration = parsedExpiration;

    this.renewalWindow = addDays(
      parsedExpiration,
      Constants.RENEWAL_NOTICE_DAYS
    );

    this.hasRecentlyExpired =
      isPast(addDays(endOfDay(parsedExpiration), PRO_GRACE_PERIOD_DAYS)) &&
      isFuture(
        addDays(endOfDay(parsedExpiration), Constants.PASSED_EXPIRATION_DAYS)
      );
  }

  shouldFetchByCountry() {
    return !this.isCountryUS() && this.isK12();
  }

  shouldFetchByZip() {
    return (
      this.isCountryUS() &&
      this.isK12() &&
      this.zipCode &&
      this.zipCode.length === 5 &&
      this.isValidZipCode()
    );
  }

  showBanner(type, message, callback) {
    this.banner.type = type;
    this.banner.message = message;

    if (callback) {
      window.setTimeout(() => {
        this.banner.message = '';
        callback();
      }, Constants.BANNER_TIMEOUT);
    }
  }

  validateAbout() {
    let valid = true;

    if (!this.isValidRole()) {
      this.errors.role.error = true;
      valid = false;
    }

    if (!this.termsChecked) {
      this.errors.terms.error = true;
      valid = false;
    }

    return valid;
  }

  validateProfileDemographics() {
    let valid = true;

    if (!this.isValidOrgType()) {
      this.errors.orgType.error = true;
      valid = false;
    }

    if (!this.isValidRole()) {
      this.errors.role.error = true;
      valid = false;
    }

    return valid;
  }

  trimDemographics() {
    if (this.city) this.city = this.city.trim();

    if (this.state) this.state = this.state.trim();

    if (this.country) this.country = this.country.trim();

    if (this.zipCode) this.zipCode = this.zipCode.trim();

    if (this.schoolName) this.schoolName = this.schoolName.trim();
  }

  validateDemographics(includeSchoolData = false) {
    let valid = true;

    if (!this.isValidOrgType()) {
      this.errors.orgType.error = true;
      valid = false;
    }

    if (!this.blingId && includeSchoolData) {
      if (!this.isValidCountry()) {
        this.errors.country.error = true;
        valid = false;
      }

      if (!this.isValidCity()) {
        this.errors.city.error = true;
        this.errors.city.message = translate('Please enter a city.');
        valid = false;
      }

      if (!this.isValidState()) {
        this.errors.state.error = true;
        this.errors.state.message = translate(
          'Please enter a state or province.'
        );
        valid = false;
      }

      if (!this.isValidZipCode()) {
        this.errors.state.error = true;
        this.errors.state.message = translate('Please enter a zip code.');
        valid = false;
      }

      if (!this.isValidSchoolName()) {
        this.errors.schoolName.error = true;
        this.errors.schoolName.message = translate(
          'Please enter a school name.'
        );
        valid = false;
      }
    }

    return valid;
  }

  /* -------------- */
  /*   Validations  */
  /* -------------- */

  isValidCountry() {
    return (
      this.country &&
      this.country.length > 0 &&
      this.country.length <= Constants.MAX_STRING_LENGTH_128
    );
  }

  isValidCity() {
    return (
      this.city &&
      this.city.length > 0 &&
      this.city.length <= Constants.MAX_STRING_LENGTH_64
    );
  }

  isValidCurrentPassword() {
    return this.currentPassword && this.currentPassword.length > 0;
  }

  isValidDistrictName() {
    return (
      !this.districtName ||
      (this.districtName &&
        this.districtName.length > 0 &&
        this.districtName.length <= Constants.MAX_STRING_LENGTH_128)
    );
  }

  isValidEmail() {
    return validations.isValidEmail(this.email);
  }

  isValidEmail2() {
    return validations.isValidEmail(this.email2);
  }

  isValidFirstName() {
    return isValidName(this.firstName);
  }

  isValidLanguage() {
    return LANGUAGES.indexOf(this.language) !== -1;
  }

  isValidLastName() {
    return isValidName(this.lastName);
  }

  isValidOrgName() {
    return (
      this.orgName === '' ||
      (this.orgName &&
        this.orgName.length > 0 &&
        this.orgName.length <= Constants.MAX_STRING_LENGTH_255)
    );
  }

  isValidOrgType() {
    return (
      this.orgType === Constants.ORGANIZATION_CORP ||
      this.orgType === Constants.ORGANIZATION_HIGHER_ED ||
      this.orgType === Constants.ORGANIZATION_K12 ||
      this.orgType === Constants.ORGANIZATION_OTHER
    );
  }

  isValidPassword() {
    return (
      this.password &&
      this.password.length >= Constants.MIN_PASSWORD_LENGTH &&
      this.password.length <= Constants.MAX_PASSWORD_LENGTH
    );
  }

  isValidPassword2() {
    return (
      this.password2 &&
      this.password2.length >= Constants.MIN_PASSWORD_LENGTH &&
      this.password2.length <= Constants.MAX_PASSWORD_LENGTH
    );
  }

  isValidRole() {
    if (!Array.isArray(this.role) || this.role.length === 0) {
      return false;
    }

    for (const role of this.role) {
      if (
        role !== Constants.ROLE_ADMINISTRATOR &&
        role !== Constants.ROLE_IT_TECHNOLOGY &&
        role !== Constants.ROLE_OTHER &&
        role !== Constants.ROLE_TEACHER
      ) {
        return false;
      }
    }

    return true;
  }

  isValidSchoolId() {
    return (
      (this.orgType === Constants.ORGANIZATION_K12 &&
        (this.schoolId === Constants.SCHOOL_NOT_LISTED ||
          /^\d+$/.test(this.schoolId))) ||
      this.schoolId === null
    );
  }

  isValidSchoolName() {
    return (
      this.schoolName &&
      this.schoolName.length > 0 &&
      this.schoolName.length <= Constants.MAX_STRING_LENGTH_255
    );
  }

  isValidState() {
    return (
      this.state &&
      (this.state === '' ||
        (this.state.length > 0 &&
          this.state.length <= Constants.MAX_STRING_LENGTH_32))
    );
  }

  isValidZipCode() {
    if (
      (this.country === 'US' || this.country === 'USA') &&
      this.orgType === Constants.ORGANIZATION_K12
    ) {
      return /^\d{5}$/.test(this.zipCode);
    }

    return true;
  }

  /* ------------------ */
  /*   Client Requests  */
  /* ------------------ */

  verifyEmail(callback) {
    request.post({
      url: `${window.teacher_service}/v3/accounts/check`,
      data: {
        email: this.email,
      },
      success: () => {
        this.emailVerified = true;
      },
      error: (e) => {
        this.emailVerified = false;
        this.errors.email.error = true;
        if (e.httpCode === 429) {
          this.errors.email.message = 'Too many requests. Please slow down.';
        } else {
          this.errors.email.message = translate(
            'This email is already in use.'
          );
        }
      },
      complete: () => {
        callback();
      },
    });
  }

  createAccount(callback) {
    request.post({
      url: `${window.teacher_service}/v3/accounts/`,
      data: {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
        city: this.city,
        country: this.country,
        orgType: this.orgType,
        schoolId: this.schoolId,
        state: this.state,
        zipCode: this.zipCode,
        description: this.description,
        // orgName: this.orgName,
        schoolName: this.schoolName,
        role: this.role,
        // districtName: this.districtName,
        phoneNumber: this.phoneNumber,
        blingId: this.blingId,
      },
      success: (data) => {
        callback(data.id);
      },
      error: () => {
        callback();
      },
    });
  }

  fetchAccount(callback, error = null) {
    request.get({
      url: `${window.teacher_service}/v3/accounts/`,
      success: (data) => {
        this.firstName = this.initialFirstName = data.firstName;
        this.lastName = this.initialLastName = data.lastName;
        this.email = this.initialEmail = data.email;
        this.emailVerified = true;
        this.language = this.initialLanguage = data.language;
        this.orgType = this.initialOrgType = data.orgType;
        this.schoolId = this.initialSchoolId = data.schoolId;

        this.country = data.country || '';
        this.country = this.country === 'USA' ? 'US' : this.country; // Reset USA to US so we don't have both.
        this.initialCountry = this.country;

        this.state = data.state || '';
        this.initialState = this.state;

        this.zipCode = data.zipCode || '';
        this.initialZipCode = this.zipCode;

        this.schoolName = data.schoolName || '';
        this.initialSchoolName = this.schoolName;

        this.city = data.city || '';
        this.initialCity = this.city;

        this.orgName = this.initialOrgName = data.orgName;

        this.role = this.initialRole = data.role;
        this.districtName = this.initialDistrictName = data.districtName;
        this.description = this.initialDescription = data.description;
        this.mcUser = data.mcUser;
        this.showChangePassword = !(this.mcUser || this.googleUser);
        this.autoRenew = data.autoRenew;
        this.canAutoRenew = data.canAutoRenew;
        this.licenseKey = data.licenseKey;
        this.customerType = data.customerType;
        this.isBuyer = data.isBuyer;
        this.hmacToken = data.hmacToken;
        this.betaFlags = data.betaFlags;
        this.phoneNumber = this.initialPhoneNumber = data.phoneNumber;
        this.blingId = this.initialBlingId = data.blingId;
        this.version = data.version;
        this.freeQuizLimit = data.freeQuizLimit;
        this.setExpiration(data.expiration);

        this.licenseModel.setPlanId(this.orgType);

        callback(data); // eslint-disable-line
      },
      error: (err) => {
        console.error(err);
        if (error && _.isFunction(error)) {
          error(err);
        }
      },
    });
  }

  saveAccount(callback) {
    const result = {};

    request.put({
      url: `${window.teacher_service}/v3/accounts/`,
      data: {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        currentPassword: this.currentPassword || '',
        password: this.password || '',
        country: this.country,
        language: this.language,
        orgType: this.orgType,
        schoolId: this.schoolId,
        state: this.state,
        zipCode: this.zipCode,
        orgName: this.orgName,
        schoolName: this.schoolName,
        districtName: this.districtName,
        role: this.role,
        phoneNumber: this.phoneNumber || null,
        blingId: this.blingId || null,
        description: this.description,
      },
      success: () => {
        this.resetPasswordFields();

        this.initialFirstName = this.firstName;
        this.initialLastName = this.lastName;
        this.initialEmail = this.email;
        this.initialCountry = this.country;
        this.initialLanguage = this.language;
        this.initialOrgType = this.orgType;
        this.initialSchoolId = this.schoolId;
        this.initialState = this.state;
        this.initialZipCode = this.zipCode;
        this.initialOrgName = this.orgName;
        this.initialSchoolName = this.schoolName;
        this.initialRole = this.role;
        this.initialDistrictName = this.districtName;
        this.initialPhoneNumber = this.phoneNumber;

        result.success = true;
        callback(result);
      },
      error: (response) => {
        result.success = false;

        if (response.error === Constants.INVALID_PASSWORD) {
          this.errors.currentPassword.error = true;
          this.errors.currentPassword.message = translate(
            'Check the passwords and try again.'
          );
          result.oldPasswordWrong = true;
        } else if (response.error === Constants.AUTH_TOKEN_MISSING) {
          result.authFailure = true;
        }

        callback(result);
      },
    });
  }

  getReceipt(callback) {
    request.get({
      url: `${window.teacher_service}/v3/accounts/receipt/email`,
      success: () => {
        callback(true); // eslint-disable-line
      },
      error: () => {
        callback();
      },
    });
  }

  deleteAccount(callback, password) {
    request.post({
      url: `${window.teacher_service}/v3/accounts/delete`,
      data: { password: password },
      success: () => {
        callback(true); // eslint-disable-line
      },
      error: () => {
        callback();
      },
    });
  }

  toJSON() {
    return {
      autoRenew: this.autoRenew,
      canAutoRenew: this.canAutoRenew,
      licenseKey: this.licenseKey,
      isBuyer: this.isBuyer,
      country: this.country,
      language: this.language,
      districtName: this.districtName,
      email: this.email,
      expiration: this.expiration,
      customerType: this.customerType,
      firstName: this.firstName,
      lastName: this.lastName,
      mcUser: this.mcUser,
      orgName: this.orgName,
      orgType: this.orgType,
      role: this.role,
      schoolId: this.schoolId,
      schoolName: this.schoolName,
      city: this.city,
      state: this.state,
      zipCode: this.zipCode,
      description: this.description,
      hmacToken: this.hmacToken,
      betaFlags: this.betaFlags,
      amplitudeData: this.amplitudeData,
      publicId: this.publicId,
      blingId: this.blingId,
      phoneNumber: this.phoneNumber,
      version: this.version,
      isPro: this.isPro,
      plan: this.plan,
      freeQuizLimit: this.freeQuizLimit,
    };
  }
}

module.exports = AccountModel;
