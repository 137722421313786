import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '~design/base/variables.css';
import '@shoelace-style/shoelace/dist/themes/light.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom';

/* ---------------------------------------------------------------------------- */
/*    These statements ensure that webpack bundles the generic code we have    */
/*    written that is common between login.js, student.js, and teacher.js.    */
import { loadClientTranslations } from '@showbie-socrative/socrative-utils/lib/translator/client';
import { enableES5, enableMapSet } from 'immer';

import { accountKeys, fetchAccount } from '~teacher/hook/account';
import { queryClient } from '~teacher/queryClient';
import { loadPermissions } from '~teacher/util/proCheck';

import { dynamicImport } from '../src/util/dynamicImport';

// This is to enable IE11 support for immer JS
// https://immerjs.github.io/immer/installation/#immer-on-older-javascript-environments
enableES5();
// to keep the bundle size small, immerjs doesn't include support for maps and sets unless you enable it.
enableMapSet();
require('../../shared/less/teacher.less');
require('../src/main.scss');
require('../src/shoelace-theme.scss');
require('extensions');

// TODO: Some of these imports are probably not needed - RLP
// Although some of them have side effects. :(

let user = require('user');
let room = require('room');

/* ---------------------------------------------------------------------------- */

let Backbone = require('backbone');

const teacherRouter = dynamicImport({
  moduleImport: () =>
    import(/* webpackChunkName: 'teacher-router' */ 'teacher-router'),
});
const HeaderController = dynamicImport({
  moduleImport: () =>
    import(/* webpackChunkName: 'HeaderController' */ 'HeaderController'),
});
const RenewalController = dynamicImport({
  moduleImport: () =>
    import(/* webpackChunkName: 'RenewalController' */ 'RenewalController'),
});
const AppView = dynamicImport({
  moduleImport: () => import(/* webpackChunkName: 'AppView' */ '../AppView'),
  moduleProperty: (module) => module.AppView,
});

const bootstrapProPermissions = async () => {
  await queryClient.invalidateQueries(accountKeys.account);
  return loadPermissions(() => fetchAccount(queryClient));
};
function joinSuccess() {
  loadClientTranslations(user.getLanguage(), () =>
    Promise.all([
      teacherRouter(),
      HeaderController(),
      RenewalController(),
      AppView(),
      bootstrapProPermissions(),
    ]).then(([router, header, renewal, AppView]) => {
      ReactDOM.render(
        React.createElement(AppView, {
          user: user,
        }),
        document.getElementById('content-container')
      );
      SOCRATIVE.checkTime.reset(); // eslint-disable-line
      // Hide the loading message (if it's visible)
      SOCRATIVE.initTemplateLoader.stop(); // eslint-disable-line

      header.initialize(router);
      renewal.initialize(router);

      if (user.wantsPro()) {
        user.setWantsPro(false);
        Backbone.history.start({ silent: true });
        router.routeToProfilePurchase();
      } else {
        Backbone.history.start();
      }
    })
  );
}

function joinError() {
  user.clearCookieData();

  // If the user was trying to import a shared quiz, store the SOC number so we can try the import after login.
  if (hashParts.length === 2 && hashParts[0] === '#import-quiz') {
    user.setSharedSocNumber(hashParts[1]);
  }

  if (window.location.pathname !== '/login/teacher/') {
    window.location.href = '/login/teacher/';
  }
}

user.setModeSilent('teacher');
user.getCookieData();

let joinOptions = {
  role: 'teacher',
  roomname: '',
  success: joinSuccess,
  error: joinError,
};

let isAdminLogin = false;

// Determine whether this is an MC SSO login. The hash portion of an MC SSO URL looks like this: #teacher-login/2a6933530d954379bd79
let hashParts = window.location.hash.split('/');
if (hashParts.length === 2 && hashParts[0] === '#teacher-login') {
  user.setAuthTokenAndMode(hashParts[1], 'teacher');

  let authParts = hashParts[1].split('?');
  joinOptions.auth_token = authParts[0];

  if (authParts[1]) {
    isAdminLogin = true;
    user.set('isAdmin', true);
  }
}

isAdminLogin = isAdminLogin || user.get('isAdmin');
if (!isAdminLogin) {
  joinOptions.setupAnalytics = true;
} else {
  console.error('Intercom disabled');
}

room.join(joinOptions);
