import * as React from 'react';

import { IconCheckmark, IconChevron } from '~shared/components/icons/standard';

import platform from '../../../../shared/Platform';
import AlertIcon from '../../icons/AlertIcon';
import dots from '../buttons/Dots';

let selectedOption = null;
let highlightedOption = null;

class CustomOption extends React.Component {
  isSelectable() {
    return this.props.selectable !== false;
  }

  optionSelected(event) {
    if (!this.isSelectable()) {
      event.preventDefault();
      return;
    }

    this.props.onSelect(this.props.name, this.props.value);
  }

  render() {
    let children = [];
    let iconClass = this.props.iconClass ? ' ' + this.props.iconClass : '';

    if (this.props.leftIcon) {
      children.push(React.cloneElement(this.props.leftIcon));
    }

    let descriptionContent = null;
    let optionTitleProps = {
      key: `${this.props.title}OptionKey`,
      children: this.props.title,
      className: !this.isSelectable() ? 'list-title-text' : '' + iconClass,
    };

    let optionContent = <span {...optionTitleProps} />;

    if (this.props.description) {
      optionContent = <strong {...optionTitleProps} />;
      descriptionContent = (
        <span
          key={`${this.props.title}Description`}
          className="description"
          // Use dangerouslySetInnerHTML because of SOC-822.
          dangerouslySetInnerHTML={{ __html: this.props.description }}
        />
      );
    }

    let selected = this.props.selected;
    let highlighted = this.props.highlighted;
    let checkMark = null;

    if (selected) {
      checkMark = this.props.isNewComponent ? (
        <IconCheckmark
          key={`${this.props.title}Selected`}
          className="icon-dropdown-checkmark"
        />
      ) : (
        <i
          key={`${this.props.title}not-selected`}
          data-icon="d"
          className="selected-checkmark"
        />
      );
    }

    children.push(optionContent, checkMark, descriptionContent);
    return (
      <li
        key={this.props.value}
        className={
          'custom-select-item' +
          (selected ? ' active' : '') +
          (!this.isSelectable() ? ' list-title' : '') +
          (highlighted ? ' highlighted-option' : '')
        }
        onMouseDown={(event) => this.optionSelected(event)}
        ref={(option) => {
          if (selected) {
            selectedOption = option;
          }

          if (highlighted) {
            highlightedOption = option;
          }
        }}
      >
        {children}
      </li>
    );
  }
}

/**
 * Socrative Custom Select
 *
 * Properties:
 *
 *      id:         {String}    the CSS id of the component
 *      name:       {String}    the name the component that will be passed to the options. it will be attached to them as data-name
 *      label:      {String}    a string saying the default valued displayed by the select if it has not selected value. it is overridden by the selected value
 *      loading:    {Boolean}   bool telling if the select should display a loading indicator. Used in standards after an option is chosen and the next select should wait for values
 *      disabled:   {Boolean}   bool telling if the select should be disabled. any click event will be ignored
 *      opened:     {Boolean}   bool telling me if select should be open. default: false
 *      onSelect:   {Function}  method called when an option is selected
 *
 *      children:   {Array}     the array containing the options. each one should have the following structure:
 *           title:         {String}         the text displayed by the option
 *           value:         {Integer/String} the value that will be returned when the option is selected
 *           leftIcon:      {Component}      a React component (e.g. svg/img) that will be shown on the left of the option text
 *           iconClass      {String}         the class to apply when a left icon is specified (e.g. for spacing the icon and the text on its right)
 *           selected:      {Boolean}        bool telling if the option is pre selected. ideally there should be only one
 *           selectable:    {Boolean}        bool telling if the option should be selectable. this creates the same behaviour as the html <optgroup>. when clicking on this the drop down should not be closed
 *           description:   {String}         text that will be shown under the option title
 *           children:      {Array}          each option can have a list of children that should have the same structure as this one
 */

export default class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      highlightedIndex: -1,
      isIE: false,
      keyDownListener: false,
      onKeyDown: this.onKeyDown.bind(this), // Make it possible to remove the onKeyDown listener.
      shouldDisplay: this.props.opened,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      shouldDisplay: nextProps.opened,
    });
  }

  componentDidUpdate() {
    if (this.state.shouldDisplay) {
      if (highlightedOption) {
        this.menu.scrollTop = highlightedOption.offsetTop;
      } else if (selectedOption) {
        this.menu.scrollTop = selectedOption.offsetTop;
      }
    }
  }

  componentWillUnmount() {
    this.selectBlurred();
  }

  selectFocused() {
    let newState = { shouldDisplay: true };

    if (!this.state.keyDownListener) {
      this.addKeyDownListener();
      newState.keyDownListener = true;
    }

    this.setState(newState);
  }

  addKeyDownListener() {
    document.addEventListener('keydown', this.state.onKeyDown);
  }

  onKeyDown(event) {
    if (event.key === 'Tab') {
      return; // Allow the user to tab away from this component.
    }

    event.stopPropagation();
    event.preventDefault();

    let nativeElement = this.input;

    if (event.key === 'Escape' || event.key === 'Esc') {
      if (nativeElement) {
        nativeElement.blur();
      } else {
        this.selectBlurred();
      }
      return;
    }

    let options = this.flattenOptions();
    let i = 0;
    let newIndex = this.state.highlightedIndex;

    if (newIndex === -1) {
      for (; i < options.length; i++) {
        if (options[i].selected) {
          newIndex = i;
          break;
        }
      }
    }

    if (event.key === 'ArrowDown') {
      if (newIndex + 1 < options.length) {
        for (i = newIndex + 1; i < options.length; i++) {
          if (!options[i].children) {
            newIndex = i;
            break;
          }
        }
      }
    } else if (event.key === 'ArrowUp') {
      if (newIndex - 1 >= 0) {
        for (i = newIndex - 1; i >= 0; i--) {
          if (!options[i].children) {
            newIndex = i;
            break;
          }
        }
      } else {
        newIndex = 0;
      }
    } else if (event.key === 'Enter') {
      if (this.state.highlightedIndex > -1) {
        let targetOption = options[this.state.highlightedIndex];
        this.props.onSelect(targetOption.name, targetOption.value);
        if (nativeElement) {
          nativeElement.blur();
        } else {
          this.selectBlurred();
        }
        return;
      }
    } else {
      for (i = 0; i < options.length; i++) {
        let candidate = options[i];
        let title = candidate.title;
        if (title) {
          title += ''; // Ensure the title compared to event.key is a string.
        }
        if (
          !candidate.children &&
          title &&
          event.key &&
          title.substr(0, 1).toLowerCase() === event.key.toLowerCase()
        ) {
          newIndex = i;
          break;
        }
      }
    }
    if (newIndex !== this.state.highlightedIndex) {
      this.setState({ highlightedIndex: newIndex });
    }
  }

  flattenOptions() {
    let options = [];

    for (let option of this.props.children) {
      options.push(option);
      if (option.children) {
        for (let subOption of option.children) {
          options.push(subOption);
        }
      }
    }

    return options;
  }

  selectBlurred() {
    if (this.state.isIE) {
      this.setState({
        shouldDisplay: true,
      });
    } else {
      highlightedOption = null;
      this.removeKeyDownListener();
      this.setState({
        shouldDisplay: false,
        keyDownListener: false,
        highlightedIndex: -1,
      });
    }
  }

  removeKeyDownListener() {
    document.removeEventListener('keydown', this.state.onKeyDown);
  }

  selectClicked(event) {
    if (
      navigator.userAgent.match(/MSIE (9|10)/) ||
      navigator.appVersion.indexOf('Trident/') > 0
    ) {
      let target = event.target.name || event.target.dataset.name;

      if (target === 'custom-select-dropdown') {
        this.setState({
          isIE: true,
        });
      } else {
        this.setState({
          isIE: false,
          shouldDisplay: false,
        });
      }
    }
  }

  optionSelected(name, value) {
    this.selectBlurred();
    this.props.onSelect(name, value);
  }

  render() {
    let customSelectList = [];
    let leftIcon = null;
    let iconClass = '';
    let firstOption = this.props.label;

    for (let option of this.props.children) {
      option.name = this.props.name;
      option.onSelect = (name, value) => this.optionSelected(name, value);
      option.key = `${option.title}OptionKey`;
      if (option.selected) {
        if (option.leftIcon) {
          leftIcon = option.leftIcon;
        }

        if (option.iconClass) {
          iconClass = ' ' + option.iconClass;
        }

        option.isNewComponent = this.props.isNewComponent;
        firstOption = option.title;
      }

      option.highlighted =
        customSelectList.length === this.state.highlightedIndex;
      customSelectList.push(<CustomOption {...option} />);

      if (Array.isArray(option.children) && option.children.length > 0) {
        for (let subOption of option.children) {
          subOption.name = this.props.name;
          subOption.key = `${subOption.value}SubOptionKey`;
          subOption.onSelect = (name, value) =>
            this.optionSelected(name, value);

          if (subOption.selected) {
            firstOption = subOption.title;
          }

          subOption.highlighted =
            customSelectList.length === this.state.highlightedIndex;
          customSelectList.push(<CustomOption {...subOption} />);
        }
      }
    }

    let loadingIndicator = null;

    if (this.props.loading) {
      loadingIndicator = dots.getReactComponent();
    }

    let containerChildren = [];

    if (leftIcon) {
      containerChildren.push(leftIcon);
    }
    let dropdownArrow = this.props.isNewComponent ? (
      <IconChevron
        key={`${this.props.label}DropdownArrow`}
        className="icon-dropdown-arrow"
      />
    ) : (
      <svg
        key="containerchildren-dropdown-arrow"
        className="custom-select-arrows"
        height="16px"
        width="11px"
        viewBox="0 0 11 16"
        dangerouslySetInnerHTML={{
          __html:
            '<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-994.000000, -2627.000000)" fill="#CCCCCC"><g transform="translate(145.000000, 2483.000000)"><g transform="translate(470.000000, 104.000000)"><g transform="translate(19.000000, 38.000000)"><g transform="translate(360.000000, 2.000000)"><path d="M7.28740083,17.9173521 C7.06028972,17.9173521 6.83317861,17.8325694 6.6600675,17.6630042 L2.61095639,13.7016999 C2.26428972,13.3627868 2.26428972,12.8132216 2.61095639,12.4743086 L6.6600675,8.51322162 C7.00628972,8.17430857 7.56851194,8.17430857 7.91473417,8.51322162 C8.26117861,8.85213466 8.26117861,9.40169988 7.91473417,9.74061292 L4.49317861,13.0880042 L7.91473417,16.4356129 C8.26117861,16.774526 8.26117861,17.3240912 7.91473417,17.6630042 C7.74162306,17.8325694 7.51428972,17.9173521 7.28740083,17.9173521" transform="translate(5.262762, 13.088194) rotate(-90.000000) translate(-5.262762, -13.088194) "></path><path d="M7.28740083,7.74096316 C7.06028972,7.74096316 6.83317861,7.65618056 6.6600675,7.48661534 L2.61095639,3.52531099 C2.26428972,3.18639795 2.26428972,2.63683273 2.61095639,2.29791969 L6.6600675,-1.66316727 C7.00628972,-2.00208031 7.56851194,-2.00208031 7.91473417,-1.66316727 C8.26117861,-1.32425423 8.26117861,-0.77468901 7.91473417,-0.435775966 L4.49317861,2.91161534 L7.91473417,6.25922403 C8.26117861,6.59813708 8.26117861,7.14770229 7.91473417,7.48661534 C7.74162306,7.65618056 7.51428972,7.74096316 7.28740083,7.74096316" transform="translate(5.262762, 2.911806) scale(1, -1) rotate(-90.000000) translate(-5.262762, -2.911806) "></path></g></g></g></g></g></g>',
        }}
        onClick={() => {
          this.input.focus();
        }}
      />
    );

    containerChildren.push(
      <input
        key="selectInput"
        className={
          'custom-select-text' +
          iconClass +
          (this.props.label === firstOption ? ' custom-select-text-label' : '')
        }
        type="text"
        readOnly={true}
        ref={(input) => (this.input = input)}
        onBlur={() => this.selectBlurred()}
        onFocus={() => this.selectFocused()}
        disabled={this.props.disabled || this.props.loading}
        value={firstOption}
      />,
      dropdownArrow,
      <span
        key="overlaySpan"
        className="overlay"
        style={{
          display: this.state.shouldDisplay ? 'block' : 'none',
        }}
      />
    );

    if (this.props.error) {
      containerChildren.push(
        <AlertIcon
          key="alertErrorIcon"
          className="select-error-icon"
          onClick={(event) => this.selectClicked(event)}
        />
      );
    }

    let ios = platform.isIos || platform.isIosApp;
    let onClickDisabled = this.props.onClickDisabled
      ? this.props.onClickDisabled
      : function() {};
    let ua = navigator.userAgent;
    let isiPad =
      /iPad/i.test(ua) ||
      /iPhone OS 3_1_2/i.test(ua) ||
      /iPhone OS 3_2_2/i.test(ua);

    return (
      <div
        id={this.props.id}
        className="custom-select"
        onClick={ios ? function() {} : onClickDisabled}
        onTouchEnd={ios ? onClickDisabled : function() {}}
      >
        <div
          key="customSelectContainer"
          className={
            'custom-select-container' +
            (this.props.error ? ' custom-select-container-error' : '')
          }
        >
          {containerChildren}
        </div>
        {loadingIndicator}
        <ul
          key="customSelectListContainer"
          className={'custom-select-list' + (isiPad ? ' no-hover-effect' : '')}
          data-name="custom-select-dropdown"
          onClick={(event) => this.selectClicked(event)}
          ref={(menu) => {
            this.menu = menu;
          }}
          style={{
            display: this.state.shouldDisplay ? 'block' : 'none',
          }}
        >
          {customSelectList}
        </ul>
      </div>
    );
  }
}
